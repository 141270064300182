import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/Post/PageTitle'
import PageBody from '../components/Post/PageBody'
import SEO from '../components/SEO'
import { updateSearchValue } from '../actions/valueChanges';

const PageTemplate = ({ data }) => {
  const { title, metaDescription, body } = data.contentfulPage;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateSearchValue(""));
  }, [])

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
      />
      <Container>
        <PageTitle>{title}</PageTitle>
        <PageBody body={body} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PageTemplate
